<template>
  <div class="home_top">
    <div class="inner inner1">
      <div class="left_item">
        <div class="wbg">
          <h1>¡Le Ayudamos A Obtener </h1>
          <h1>Un Préstamo Más </h1>
          <h1>Fácilmente!</h1>
          <h4>En 4 Pasos Sencillos, Obtenga Su Dinero En Un</h4>
          <h4>Plazo De 2 A 24 Horas.</h4>
        </div>
        <div class="item_download" @click="downloadApp()">
          <img src="@/assets/images/Google.png" alt="">
        </div>
      </div>
      <div class="right_item">
        <!-- <img src="@/assets/images/banner01.png" alt=""> -->
      </div>
    </div>
    <div class="inner inner2">
      <h3>Por qué elegir BonoUp</h3>
      <p>BonoUp es una aplicación que ofrece servicio de préstamos en línea.</p>
      <div class="inner2_item flex jc-sb">
        <div v-for="(item,index) in leftItemList" :key="index" class="content">
          <!-- <img class="bg" :src="item.img" alt=""> -->
          <img class="bg2" :src="item.img2" alt="">
          <div class="item_info">
            <!-- <h4>{{ item.title }}</h4> -->
            <h1>{{ item.txt }}</h1>
            <p>{{ item.txt2 ? item.txt2 : `&nbsp;` }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="inner inner3">
      <h3>Ventajas de nuestros productos</h3>
      <p>Préstamos de crédito en efectivo.Resuelva rápido emergencia personal por celular.</p>
      <div class="flex fd-c">
        <div v-for="(item,index) in inner_3items" :key="index" class="content flex" :class="index %2 === 1 ?'fd-r jc-sb cloor2':''">
          <img :src="item.img">
          <div class="content2 flex fd-c jc-sb">
            <h1>{{ item.title }}</h1>
            <div v-for="(item2,index2) in item.list2" :key="index2">
              <p v-if="item2.txt">{{ item2.txt }}</p>
              <h2 v-if="item2.txt2">{{ item2.txt2 }}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="selector1" class="inner inner4">
      <h3>Proceso de préstamo</h3>
      <h4>Solicitar con unos pasos, sin papeleos tediosos, sin filas largas</h4>
      <div class="flex al-c jc-c">
        <div class="carousel-container">
          <div class="carousel">
            <transition-group name="fade" tag="div" class="carousel-wrapper">
              <div v-for="(item) in inner_4items" :key="item.id" class="carousel-item ai-c jc-c">
                <!-- <p class="title">{{ item.id }}</p> -->
                <img :src="item.img">
                <h1>{{ item.txt }}</h1>
                <p>{{ item.txt2 }}</p>
                <img v-if="Number(item.id) % 3 !== 0" class="right" src="@/assets/images/xiangyou@2x.png">
              </div>
            </transition-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      leftItemList: [
        { txt: 'Préstamo rápido', txt2: 'Una vez aprobado el sistema, el dinero se \n transferirá directamente a su tarjeta bancaria.', img: require('@/assets/images/itemList_01.png'), img2: require('@/assets/images/itemList_04.png') },
        { txt: 'Operación simple', txt2: 'Descargue, instale la aplicación y solicite un \n préstamo rápido. El sistema aprueba automáticamente \n el monto del préstamo.', img: require('@/assets/images/itemList_02.png'), img2: require('@/assets/images/itemList_05.png') },
        { txt: 'Interés bajo', txt2: 'El interés del préstamo es muy competitivo \n y ofrece ofertas especiales.', img: require('@/assets/images/itemList_03.png'), img2: require('@/assets/images/itemList_06.png') }
      ],
      leftItemList2: [
        { title: 'What can you get?', list2: [{ img: require('@/assets/images/list01.png'), txt: 'From 1,000 ₱ up to 50,000 ₱' }, { img: require('@/assets/images/list02.png'), txt: 'Repayment Term：0-180 days' }, { img: require('@/assets/images/list03.png'), txt: 'Annual interest rate：10%-35%' }] },
        { title: 'Who is eligible to get a loan?', list2: [{ img: require('@/assets/images/list04.png'), txt: 'Age 18-60 years' }, { img: require('@/assets/images/list05.png'), txt: 'Philippines citizen' }, { img: require('@/assets/images/list06.png'), txt: 'Employed or Business-owner' }] }
      ],
      inner5ItemLeftList: [
        { title: 'Online service', txt: 'You can find online services in ', txt2: 'the BonoUp app.', img: require('@/assets/images/ng_1.png') },
        { title: 'E-mail', txt: 'tigercreditapp@gmail.com', img: require('@/assets/images/ng_2.png') },
        { title: 'Customer service hours', txt: '9AM-6PM', img: require('@/assets/images/ng_3.png') }
      ],
      items: [
        // 您的轮播内容数据
        { id: '1', txt: 'Antonio Maldonado Evangelista', txt2: 'Me chifla mucho BonoUp que es una app de \n préstamo persomal online súper rápido.Solo con 5 \n minutos ya conseguí 2.000 pesos.Me traio unagran \n ayuda a mi y a mi familia.', img: require('@/assets/images/Product01.png') },
        { id: '2', txt: 'Teri Dol Blance', txt2: 'Creo que en muy poco tiempo esta aplicación se \n ha convertido en un verdadero amigo que me \n necesita, ya que pude solucionar todos mis \n problemas financieros con la ayuda de planes \n de préstamos instantáneos.', img: require('@/assets/images/Product02.png') },
        { id: '3', txt: 'IGNACIO HERNANDEZ CRUZ', txt2: 'El proceso de la solicitud del préstamo es muy \n sencillo. El préstamo está con bajo interés del \n monto alto.Me brinda muchas', img: require('@/assets/images/Product03.png') },
        { id: '4', txt: 'Erika Kassandra Bravo', txt2: 'Cuando me urge dinero me recomendaror \n BonoUp que es uan app super buena!', img: require('@/assets/images/Product04.png') },
        { id: '5', txt: 'ADALBERTO SEGURA RIVERA', txt2: 'Excelente recurso, me ayudó a quedarme atascado. \n Esta aplicación es genial, te ayuda a administrar tus \n objetivos financieros y a mantenerte al día.', img: require('@/assets/images/Product05.png') }
      ],
      visibleItemCount: 3,
      currentIndex: 0,
      inner_4items: [
        // 您的轮播内容数据
        { id: '1', name: 'Lando Wolf', txt: 'Descarga la App', txt2: 'Vaya a GooglePlay para descargar \n BonoUp ', img: require('@/assets/images/icon-1.png') },
        { id: '2', name: 'Alice Ura', txt: 'Registrarse y verificar', txt2: 'Debe registrarse y completar la \n información requerida para terminar \n la verificación.', img: require('@/assets/images/icon-2.png') },
        { id: '3', name: 'Marino Zavaleta', txt: 'Solicitar préstamos', txt2: 'Puede elegir el monto de préstamos \n de acuerdo con sus necesidades y \n agregar una cuenta para recibir el \n dinero.', img: require('@/assets/images/icon-3.png') },
        { id: '4', name: 'Obtención de \n préstamos', txt: 'Obtención de \n préstamos', txt2: 'Una vez aprobado el sistema, el dinero \n se transferirá directamente a su tarjeta \n bancaria.', img: require('@/assets/images/icon-4.png') },
        { id: '5', name: 'Alhertina Rayon', txt: 'Pagar el préstamo a \n tiempo', txt2: 'Para aumentar el límite de crédito y \n evitar multas, debe pagar a tiempo \n los préstamos antes de las fechas \n de vencimiento.', img: require('@/assets/images/icon-5.png') },
        { id: '6', name: 'Alhertina Rayon', txt: 'Volver a pedier \n préstamos', txt2: 'Nos complace ayudarlo a alcanzar \n la vida de su sueño y esperamos \n poder brindarle ayuda nuevamente.', img: require('@/assets/images/icon-5.png') }
      ],
      inner_3items: [
        { img: require('@/assets/images/inner_4_1.png'), title: 'Monto alto, \n desembolso rápido', list2: [{ txt: 'Puede elegir el monto y el plazo libremente. \n El monto del préstamo es de hasta $50,000. \n Se transferirá directamente a la cuenta bancaria \n y el préstamo se liberará en tan solo 5 minutos.' }, { txt: '' }, { txt: '' }] },
        { img: require('@/assets/images/inner_4_2.png'), title: 'Bajo interés, seguro \n y confiable', list2: [{ txt: 'El interés del préstamo es muy competitivo y o \n frece ofertas especiales. Los procesos son \n simples y seguros.' }, { txt: '' }] },
        { img: require('@/assets/images/inner_4_3.png'), title: 'Fácil acceso a los \n préstamos', list2: [{ txt2: 'Solo necesitas enviar una solicitud, te atenderemos \n durante todo el proceso. Sin salir de casa, el depósito \n llega en sólo 5 minutos, para que digas adiós a tu crisis \n financiera y empieces a vivir feliz!' }] },
        { img: require('@/assets/images/inner_4_4.png'), title: 'Tu dinero cuando \n quieras', list2: [{ txt: 'Preste dinero todo el día los 365 días, acceso a \n tu salario ya trabajado 24/7. Sin salir de casa, \n sin largas filas, sin papeleo tedioso.' }] }
      ],
      inner6ItemLeftList: [
        { title: 'apexlendinghelp@outlook.com', img: require('@/assets/images/email.png') },
        { title: '78 Ogunnusi Road, Ojodu Berger, Lagos State.', img: require('@/assets/images/address.png') },
        { title: '9AM-5PM', img: require('@/assets/images/time.png') }
      ]
    }
  },
  computed: {
    visibleItems() {
      return this.items.slice(this.currentIndex, this.currentIndex + this.visibleItemCount)
    }
  },
  created() {
    // setInterval(() => {
    //   this.nextSlide()
    // }, 4)
  },
  methods: {
    inner_2Class(index) {
      if (index === 4) {
        return 'content2'
      } else if (index % 2 !== 0) {
        return 'text_r'
      }
    },
    downloadApp() {
      window.open('https://play.google.com/store/apps/details?id=bonoup.prestamo.rapido', '_blank')
    },
    previousSlide() {
      if (this.currentIndex > 0) {
        this.currentIndex--
      }
    },
    nextSlide() {
      if (this.currentIndex < this.items.length - this.visibleItemCount) {
        this.currentIndex++
      } else {
        this.currentIndex--
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.home_top{
  min-width: 1200px;
  background: #fff;
  white-space: pre-line;
  .inner{
    width: 100%;
    padding:60px calc((100% - 1100px) / 2);
    margin: 0 auto;
  }
  .inner1{
    // background: linear-gradient(258deg, #43C5D6 0%, #4484EC 100%);
    padding:60px calc((100% - 1100px) / 2);
    background: url("~@/assets/images/loanImg/inner1_bg.png") #D6F5FE no-repeat ;
    background-size: 40% 75%;
    background-position: 85% 50%;
    height: 550px;
    display: flex;
    justify-content: space-between;
    .left_item{
      box-sizing: border-box;
      border-radius: 10px;
      h1{
        font-size: 40px;
        color: #000093;
        font-weight: 700;
        // margin-bottom: 10px;
        line-height: 70px;
        span{
          color: #00BB53;
        }
      }
      h4{
        font-size: 16px;
        line-height: 30px;
        color: #000093;
        font-weight: normal;
        // margin: 0px 0;
      }
      & h4:nth-of-type(1){
        margin-top: 10px;
      }
      .item_info{
        margin: 20px 0;
        display: flex;
        height: 170px;
        .info_img1{
          width: 18px;
        }
        .info_tips{
          display: flex;
          flex-direction: column;
          p{
            flex: 1;
            display: flex;
            box-sizing: border-box;
            // margin-left: 10px;
            font-size: 16px;
            color: #FFFFFF;
            align-items: center;
            img{
              margin-right: 10px;
            }
          }
        }
        .info_img2{
          width: 135px;
          height: 135px;
        }
      }
      .item_download{
        // padding-left: 22px;
        cursor: pointer;
        margin-top: 40px;
        color: white;
        border-radius: 5px;
        img{
          width: 190px;
          height: 60px;
          margin-right: 5px;
        }
      }
    }
    .right_item{
      img{
        height: 360px;
        margin-right: 30px;
      }
    }
  }
  .inner2{
    position: relative;
    padding:80px calc((100% - 1100px) / 2) 60px;
    // background: linear-gradient(86deg, #013799 0%, #0071D6 100%);
    h3{
      font-size: 30px;
      text-align: center;
      margin-bottom: 30px;
    }
    p{
      text-align: center;
      color: #666666;
      font-size: 14px;
      margin-bottom: 60px;
    }
    .content{
      // width: 35%;
      // margin: 0 10px;
      // padding: 80px 0;
      // background: #F1F5FF;
      border-radius: 20px;
      width: 31%;
      text-align: center;
      color:black;
      padding: 0 0 3pc 0;
      .bg{
        width: 100%;
      }
      .bg2{
        display: block;
        width: 30%;
        margin: 0px auto 0;
      }
      p{
        color: #666666;
        font-size: 13px;
        line-height: 20px;
        font-weight: bold;
      }
      .item_info{
        h1{
          color: #0049A4;
          font-size: 20px;
          line-height: 50px;
          margin-top: 10px;
        }
      }
    }
  }
  .inner3{
    position: relative;
    padding:90px calc((100% - 1000px) / 2) 60px;
      // background: linear-gradient(86deg, #013799 0%, #0071D6 100%);
    background: #F5F5F5;
    h3{
      font-size: 30px;
      color: black;
      margin-bottom: 30px;
      text-align: center;
    }
    p{
      text-align: center;
      font-size: 16px;
      margin-bottom: 70px;
    }
    .content{
      // width: 35%;
      // margin: 0 10px;
      // padding: 80px 0;
      width: 100%;
      text-align: left;
      color:white;
      margin-bottom: 60px;
      background: #FFFFFF;
      h1{
        font-size: 24px;
        margin-bottom: 30px;
        line-height: 34px;
        // margin:0 0 50px 0;
        position: relative;
      }
      p{
        text-align: left;
        font-size: 15px;
        font-weight: bold;
        width: 80%;
        // margin-left: 28px;
        position: relative;
        margin-bottom: 20px;
        // &::before{
        //   position: absolute;
        //   display: block;
        //   content:'';
        //   width: 10px;
        //   height: 10px;
        //   border-radius: 5px;
        //   background: #0071D6;
        //   margin:0 10px 5px 0;
        //   left: -28px;
        //   top: 7px;
        // }
      }
      h2{
        color: #666666;
        text-align: left;
        font-size: 15px;
        margin-bottom: 20px;
        font-weight: 100 !important;
        line-height: 23px;
      }
      img{
        width: 500px;
      }
    }
    .cloor2{
        background: #0049A3 !important;
        h1,p{
          color: white !important;
        }
      }
    .content2{
      width: 42%;
      text-align: left;
      color: black;
      margin-left: 50px;
      justify-content: center;
      p{
        color: #666666;
        font-weight: 100 !important;
        line-height: 23px;
      }
    }
  }
  .inner4{
      background: #F5F5F5;
      padding: 70px calc((100% - 1000px) / 2) 40px;
      h3{
        font-size: 24px;
        color: black;
        text-align: center;
        margin-bottom: 30px;
        position: relative;
        // &::after{
        //   display: block;
        //   content:'';
        //   width: 80px;
        //   height: 5px;
        //   background: #00BB53;
        //   margin: 20px auto 0;
        // }
      }
      h4{
        text-align: center;
        font-size: 16px;
        margin-bottom: 70px;
        color: #666666;
        font-weight: normal;
      }
      p{
        padding: 10px 0px;
        font-size: 12px;
        line-height: 18px;
        color: #666666;
      }
      .carousel-container {
        position: relative;
        width: 100%;
        height: 800px;
      }
      .carousel {
        display: flex;
        overflow: hidden;
        height: 100%;
      }
      .carousel-wrapper {
        display: flex;
        width: 100%;
        justify-content:space-around;
        align-items: center;
        flex-wrap: wrap;
      }
      .carousel-item {
        width: 30%;
        height: 350px;
        display: flex;
        flex-direction: column;
        align-items: center;
        // justify-content: center;
        // background: url("~@/assets/images/content_bg.png") no-repeat center;
        background: #fff;
        background-size: 120% 100%;
        color: #fff;
        border-radius: 5px;
        font-size: 18px;
        padding: 30px 0 0;
        position: relative;
        margin-bottom: 20px;
        box-shadow: 0px 6px 30px 1px rgba(178,178,178,0.16);
        // margin:  0 10px;
        .title{
          color: #fff;
          font-size: 20px;
          font-weight: bold;
        }
        h1{
          color: black;
          font-size: 18px;
          line-height: 25px;
          margin: 25px 0;
          text-align: center;
          height: 35px;
          padding: 0 20px;
        }
        p{
          font-size: 12px;
          height: 80px;
        }
        img{
          width: 70px;
        }
        .right{
          position: absolute;
          top: calc(50% - 20px);
          right: -42px;
          width: 50px;
          z-index: 999;
        }
      }
      .fade-enter-active,
      .fade-leave-active {
        transition: opacity 0.2s, transform 0.2s;
      }

      .fade-enter,
      .fade-leave-to {
        opacity: 0;
        transform: translateX(300px);
      }
    }
}
@media only screen and (max-width: 720px){
  .home_top{
    min-width: calc(100vw) !important;
    .inner{
      min-width: calc(100vw) !important;
      display: flex;
      flex-wrap: wrap;
    }
    .inner1{
      padding: 0px calc((100% - 1200px) / 2) 0;
      height: 530px;
      position: relative;
      background-size: 80% auto;
      background-position: bottom;
      .left_item{
        // margin: 0px 10px 10px;
        padding: 30px 0px 0 0 !important;
        flex: 1;
        h1{
          font-size: 25px;
          line-height: 35px;
          // line-height: 50px;
          color: #000093 !important;
          padding-left: 30px;
        }
        h4{
          font-size: 12px;
          margin: 0 !important;
          padding-left: 30px;
        }
        & h4:nth-of-type(1){
          margin-top: 60px;
        }
        .item_download {
          margin-top: 20px;
          padding-left: 0 !important;
          img{
            display: block;
            width: 150px;
            height: 50px;
            margin: 0 auto;
          }
        }
        .wbg{
          // background: linear-gradient(86deg, #013799 0%, #0071D6 100%);
        }
        .item_info{
          background: #063EA4;
          padding-left: 10px;
          margin: 30px 0;
          justify-content: center;
          .info_tips{
            p{
              font-size: 12px;
            }
            img{
              width: 15px;
            }
          }
        }
      }
      .right_item{
        width: calc(100vw);
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          width: 100% !important;
          position: absolute;
          bottom: 0;
          margin-right: 0;
          height: 350px;
        }
      }
    }
    .inner2{
      padding: 50px calc((100% - 1200px) / 2) 0px;
      .inner2_bg{
        background: none;
      }
      div{
        width: 100%;
        padding: 0 20px;
      }
      h3{
        width: 100%;
        font-size: 25px;
        text-align: center;
        margin-bottom: 30px;
      }
      p{
        margin-bottom:20px;
      }
      .inner2_item{
        flex-direction: column;
      }
      .content{
        width: 100%;
        // padding: 20px 0;
        h1{
          margin: 10px 0 20px;
          font-size: 20px;
          text-align:  center !important;
        }
        p{
          margin-bottom: 10px;
        }
      }
      .text_r{
        text-align: left !important;
        p{
          text-align: left !important;
        }
      }
      .content2{
        text-align: left;
        p{
        text-align: left;
        }
      }
    }
    .inner3{
      min-width: calc(100vw - 40px) !important;
      width: calc(100vw) !important;
      margin: 0 auto !important;
      display: none;
      padding: 50px 0px 0;
      div{
        width: 100%;
      }
      p{
        padding: 10px 0 20px;
        margin-bottom: 10px;
      }
      h3{
        width: 100%;
        font-size: 25px;
        text-align: center;
        margin-bottom: 20px;
      }
      .content{
        flex-direction: column;
        margin-bottom: 00px;
        h1{
          font-size: 20px;
          margin: 20px 0;
        }
        img{
          width:100% !important;
          margin: 0 auto;
        }
        p{
          width: 80% !important;
          margin: 0 auto;
          font-size: 12px;
        }
      }
      .content2{
        width: 100%;
        align-items: center;
        justify-content: center;
        text-align: center !important;
        padding: 0 !important;
        margin-left: 0;
        h2{
          margin: 0 20px;
          font-size: 12px;
          margin-bottom: 20px;
        }
      }
      .left_bg{
        width: 30px;
        height: 50px;
        top: 40px;
      }
      .inner5_item{
        margin:  0 auto;
        padding: 0;
        display: flex;
        flex-direction: column;
        .content{
          flex: 1;
          width: 100%;
          margin: 20px 0;
        }
      }
      .carousel-button{
        padding: 0 10px;
        img{
          width: 10px;
        }
      }
      .carousel-container{
        height: 600px;
      }
      .carousel-wrapper{
        padding: 0 20px;
        flex-wrap: wrap;
        .carousel-item{
          width: 45%;
          margin: 0 5px;
        }
      }
      .carousel-item{
        width: 150px;
        height: 280px;
        img{
          width: 60px;
        }
        h1{
          font-size: 13px;
          height: 40px;
          padding: 0;
          line-height: 16px;
        }
        p{
          font-size: 12px;
          height: 90px;
          padding: 0 5px;
          line-height: 18px;
        }
      }
    }
    .inner4{
      min-width: calc(100vw - 40px) !important;
      width: calc(100vw) !important;
      margin: 0 auto !important;
      padding: 50px 0px;
      div{
        width: 100%;
      }
      .right{
        display: none;
      }
      p{
        padding: 10px 0 20px;
      }
      h3{
        width: 100%;
        font-size: 25px;
        text-align: center;
        margin-bottom: 20px;
      }
      h4{
        margin-bottom: 30px;
      }
      .left_bg{
        width: 30px;
        height: 50px;
        top: 40px;
      }
      .inner5_item{
        margin:  0 auto;
        padding: 0;
        display: flex;
        flex-direction: column;
        .content{
          flex: 1;
          width: 100%;
          margin: 20px 0;
        }
      }
      .carousel-container{
        height: 1500px;
      }
      .carousel-wrapper{
        padding: 0 20px;
        flex-wrap: wrap;
        .carousel-item{
          width: 45%;
          margin: 0 5px;
        }
      }
      .carousel-item{
        width: 60% !important;
        height: 240px;
        img{
          width: 60px;
        }
        h1{
          font-size: 13px;
          padding: 0;
          line-height: 16px;
          margin: 10px 0;
        }
        p{
          font-size: 12px;
          height: 90px;
          padding: 0 5px;
          line-height: 18px;
        }
      }
    }
  }
}
</style>
